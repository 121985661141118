<template>
	<DxDataGrid
		:ref="dataGrid.refName"
		:data-source="dataGrid.dataSource"
		:show-borders="dataGrid.showBorders"
		:show-column-headers="dataGrid.showColumnHeaders"
		:show-column-lines="dataGrid.showColumnLines"
		:show-row-lines="dataGrid.showRowLines"
		:row-alternation-enabled="dataGrid.rowAlternationEnabled"
		:allow-column-reordering="dataGrid.allowColumnReordering"
		:width="dataGrid.width"
		:height="dataGrid.allowAdding ? dataGrid.height : dataGrid.height - 55"
		:no-data-text="dataGrid.noDataText"
		:key-expr="dataGrid.keyExpr"
		:visible="dataGrid.visible"
		@toolbar-preparing="onToolbarPreparing"
		@selection-changed="onSelectionChanged"
		@row-updating="onRowUpdating"
		@row-inserting="onRowInserting"
		@row-removed="onRowRemoved"
	>

		<DxEditing
			:allow-updating="dataGrid.editing.allowUpdating"
			:allow-deleting="dataGrid.editing.allowDeleting"
			:allow-adding="dataGrid.editing.allowAdding"
			:mode="dataGrid.editing.mode"
			:select-text-on-edit-start="dataGrid.editing.selectTextOnEditStart"
			:start-edit-action="dataGrid.editing.startEditAction"

		>
			<DxTexts
				confirm-delete-message="">
			</DxTexts>
		</DxEditing>
		<DxSelection
			v-if="dataGrid.selecting"
			:mode="dataGrid.selecting.mode"
			:show-check-boxes-mode="dataGrid.selecting.showCheckBoxesMode"
			:select-all-mode="dataGrid.selecting.selectAllMode"
		/>
		<DxPaging :enabled="false" />
		<DxPager :visible="false" />
		<template v-for="(column, index2) in dataGrid.columns">
			<DxColumn
				v-if="column.dataField"
				:key="index2"
				:caption="column.caption"
				:data-field="column.dataField"
				:width="column.width"
				:alignment="column.alignment"
				:visible="column.visible"
				:cell-template="column.cellTemplate"
				:allow-editing="column.editing"
				:sort-order="column.sortOrder"
				:data-type="column.dataType"
			>
				<DxNumericRule v-if="column.dataField === 'num'" message="숫자만 입력 하세요."/>
				<DxPatternRule
					v-if="column.dataField === 'num'"
					pattern="^[1-9][0-9]?$|^99$"
					message="1~99 숫자만 입력해 주세요."
				/>
				<DxRequiredRule v-if="column.requiredRule"
					:message="column.requiredRule.message"
				/>
			</DxColumn>
		</template>
	</DxDataGrid>
</template>
<script>
import {
	DxDataGrid,
	DxColumn,
	DxPaging,
	DxPager,
	DxSelection,
	DxEditing,
	DxTexts,
	DxRequiredRule,
	DxNumericRule,
	DxPatternRule
} from 'devextreme-vue/data-grid';


export default {
	components: {
		DxRequiredRule,
		DxTexts,
		DxEditing,
		DxColumn,
		DxDataGrid,
		DxPaging,
		DxPager,
		DxSelection,
		DxNumericRule,
		DxPatternRule,
	},
	props: {
		dataGrid: {
			type: Object,
		},
	},
	watch: {},
	data() {
		return {
			selectedRowsData: [],
		};
	},
	computed: {
		getGridInstance() {
			return this.$refs[this.dataGrid.refName].instance;
		},
	},
	methods: {
		/** @description detail.vue 에서 사용중 */
		clearSelection(){
			this.$refs[this.dataGrid.refName].instance.clearSelection();
		},
		/** @description detail.vue 에서 사용중 */
		hasEditData(){
			return this.$refs[this.dataGrid.refName].instance.hasEditData();
		},
		onSelectionChanged(e){
			this.selectedRowsData = e.selectedRowsData;
		},
		onRowInserting(e) {
			this.$emit('row-inserting', e);
		},
		onRowUpdating(e){
			this.$emit('row-updating', e)
		},
		onRowRemoved(e) {
			this.$emit('row-removed', e);
		},
		onToolbarPreparing(e) {
			const toolbarItems = e.toolbarOptions.items;
			toolbarItems.forEach(item => {
				if (item.name === 'saveButton') {
					item.location = 'before';
					item.sortIndex = 40;
					item.options.icon = '';
					item.options.text = '저장';
					item.showText = 'always';
					item.options.elementAttr = { class: 'btn_XS default filled ' };
					item.options.width = '60';
					item.options.height = '30';
				} else if (item.name === 'addRowButton') {
					item.location = 'before';
					item.sortIndex = 30;
					item.options.icon = '';
					item.options.text = '추가';
					item.showText = 'always';
					item.options.elementAttr = { class: 'btn_XS default filled add1' };
					item.options.width = '60';
					item.options.height = '30';
				} else if (item.name === 'revertButton') {
					item.location = 'before';
					item.sortIndex = 50;
					item.options.icon = '';
					item.options.text = '취소';
					item.options.elementAttr = { class: 'btn_XS white light_filled ' };
					item.showText = 'always';
					item.options.width = '60';
					item.options.height = '30';
				}
			});

			if (this.dataGrid.showActionButtons.customButtons) {
				this.dataGrid.showActionButtons.customButtons.forEach((d, i) => {
					if (!d.sortIndex) d.sortIndex = Number('7' + (i + 1));
					toolbarItems.push(d);
				});
			}
		},
	},
};
</script>
<style scoped></style>
